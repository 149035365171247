import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { Const } from "../const/const";
import { GetInSiteMeterResponse } from "../entity/get-in-site-meters-response";
import { LoginUser } from "../entity/login-user";
import { MessageInfo } from "../entity/message-info";
import { Meter } from "../entity/meter";
import { PostMeterValueConfirmationResponse } from "../entity/post-meter-value-confirmation-response";
import { PostValueConfirmationWithValueDetectionResponse } from "../entity/post-value-confirmation-with-value-detection-response";
import { ValueConfirmation } from "../entity/value-confirmation";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { debug } from "console";
import { MeterInfo } from "../entity/get-meter-info";
import { MeterQrcodeDialogComponent } from "../meter-qrcode-dialog/meter-qrcode-dialog.component";
import { userInfo } from "os";
import { ValueDetection } from "../entity/value-detection";
import { LastMonthValueConfirmation } from "../entity/last-month-value-confirmation";
import { BigNumber } from "bignumber.js";

export interface Detect {
  img_b64: string;
}

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(0)" })),
      state("rotated", style({ transform: "rotate(-360deg)" })),
      transition("rotated => default", animate("2000ms ease-out")),
      transition("default => rotated", animate("2000ms ease-in")),
    ]),
  ],
})
export class CardComponent implements OnInit {
  @Output() dialogClose: EventEmitter<string> = new EventEmitter();
  @Input() meterData: Meter;
  @Input() loginUser: LoginUser;
  @Input() canEdit: boolean;
  @Input() usingPlace: string;
  @Input() selectedMonth: string;
  @Input() tenantId: string;
  @Input() label: string;
  @Input() confirmedReportFlg: boolean;

  //debug
  @Input() tenantName: string;
  @Input() partName: string;
  //debug

  keepValue: LastMonthValueConfirmation;
  keepLabel: string;

  postValueConfirmationUrl: string;
  postValueConfirmationNoImageUrl: string;
  getOneMeterDataUrl: string;
  formControl: UntypedFormGroup;
  messageInfo: MessageInfo;
  idToken: string;
  get detected_value() {
    return this.formControl.get("detected_value");
  }

  get flagImgUrl() {
    return this.meterData.is_reviewed
      ? "../assets/bookmark_check_blue.png"
      : "../assets/bookmark_emp_blue.png";
  }

  state: string = "default";
  //debug
  public myAngularxQrCode: string = null;
  qrcodeTitle: string;
  //debug

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    public auth: AuthService
  ) {}

  ngOnInit() {
    if (this.meterData !== undefined) {
      this.formControl = new UntypedFormGroup({
        detected_value: new UntypedFormControl("", [
          Validators.required,
          kenshinValueCheck,
        ]),
      });
      this.keepValue = this.meterData.last_month_value_confirmation;
      this.keepLabel = this.meterData.label;
    }
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
      }
    });
  }

  //指定した小数点まで切り捨てる
  confirmedValueFormat(val: string, point: number) {
    let formatedNum = "";

    let ten = new BigNumber(Math.pow(10, point));
    let tmpNum = new BigNumber(val);
    let result = tmpNum.times(ten);
    result = new BigNumber(Math.floor(Number(result)));
    let endRes = result.dividedBy(ten);
    formatedNum = endRes.toString();

    return formatedNum;
  }

  // DB⇨画面表示で日付のフォーマットを変更する
  changeDateFormat(date: string) {
    const changedDate = new Date(date + "Z").toLocaleString();
    return changedDate;
  }

  // 「yyyy-mm-dd hh:mm:ss」形式に変換する　かつ　＋９時間（日本時間にする）
  changeLocaleDateFormat(date: string) {

    // 空文字で来たらそのまま返す
    if (date === "") {
      return "";
    }

    // 変換できなければ空文字
    let newD: Date;
    try {
      newD = new Date(new Date(date).setHours(new Date(date).getHours() + 9));
    } catch(error) {
      return "";
    }

    const yyyy = String(newD.getFullYear());
    const mm = String(newD.getMonth() + 1).padStart(2, "0");
    const dd = String(newD.getDate()).padStart(2, "0");
    const hh = String(newD.getHours()).padStart(2, "0");
    const ms = String(newD.getMinutes()).padStart(2, "0");
    const s = String(newD.getSeconds()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd} ${hh}:${ms}:${s}`;
  }

  onConfirmBtn(val: string) {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);
    this.doPostAction(val);
  }

  onFlagBtn(meter_id: string, flag: boolean) {
    let params = new HttpParams();
    params = params.set("is_reviewed", Number(!flag).toString());
    this.postValueConfirmationUrl = `${environment.apiUrl}/meters/${meter_id}/is_reviewed`;
    this.httpClient
      .patch(this.postValueConfirmationUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: PostMeterValueConfirmationResponse) => {
          this.doGetOneMeterData(this.meterData.id, this.selectedMonth);
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert("見直しフラグの変更に失敗しました");
          }
        }
      );
  }

  // TODO: hiddenなので辿り着かない
  onAnalysisImageBtn() {
    if (this.meterData.value_detections[0]) {
      this.state = this.state === "default" ? "rotated" : "default";
      const apiUrl = "https://ai-analytics-api.rakusugikenshin.com/detect";
      const imgUrl = this.meterData.value_detections[0].picture_url;
      fetch(imgUrl, {
        mode: "cors",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Authorization,Content-Type,X-Amz-Date,X-Amz-Security-Token,X-Api-Key",
          "Access-Control-Allow-Methods": "GET,OPTIONS",
        },
      })
        .then((response) => {
          return response.blob();
        })
        .then((blob) => new File([blob], "meter.jpeg"))
        .then((file) => {
          const reader = new FileReader();
          let base64Output;
          reader.readAsDataURL(file);
          reader.onload = () => {
            const dataURI = String(reader.result);
            base64Output = dataURI.replace(
              /data:application\/octet-stream;base64,/,
              ""
            );
            const body: Detect = {
              img_b64: base64Output,
            };
            this.httpClient
              .post(apiUrl, body, {
                headers: new HttpHeaders({
                  Authorization: this.idToken,
                }),
              })
              .subscribe(
                (res) => {
                  const value = String(
                    Number(res["result"]) /
                      Math.pow(
                        10.0,
                        Number(this.meterData.decimal_point_position)
                      )
                  );
                  this.doPostAction(value);
                  // this.meterData.value_detections[0].value_confirmation.confirmed_value = value
                },
                (err) => {
                  if (err.error.code == "AccountLockError") {
                    alert(
                      "アカウントがロックされました。管理者までお問合せください"
                    );
                    Const.release();
                    this.auth.signOut();
                  } else {
                    alert("検針値の確定に失敗しました。");
                  }
                }
              );
          };
        });
    } else {
      alert("写真なしではアップロードできません");
    }
  }

  onConfirmBtnNoImage(
    val: string,
    decimal: string,
    valueDetections: ValueDetection[]
  ) {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);
    this.messageInfo = new MessageInfo(
      "この確定操作は行えません。楽杉けんしん保守窓口サポート担当までご連絡ください。",
      ""
    );

    // 操作年月チェック
    // レポート年月より後に確定しようとしたらアラートで止める
    if (Number(this.selectedMonth) < Number(thisMonth)) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: "800px",
        data: this.messageInfo,
      });
    } else {
      this.doPostActionNoImage(val, Number(decimal), valueDetections);
    }
  }

  doPostAction(confirmedVal: string) {
    // Postする際のパラメータをセットする
    let params = new HttpParams();
    params = params.set(
      "value_detection_id",
      this.meterData.value_detections[0].id
    );
    params = params.set("confirmed_value", confirmedVal);
    params = params.set("confirmation_channel", "1");
    params = params.set("user_id", this.loginUser.id);
    this.postValueConfirmationUrl = `${environment.apiUrl}/value_confirmations`;
    this.formControl.setValue({ confirmed_value: null });
    this.httpClient
      .post(this.postValueConfirmationUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: PostMeterValueConfirmationResponse) => {
          if (
            this.meterData.value_detections[0].value_confirmation === undefined
          ) {
            const newValueConfirmation = new ValueConfirmation(
              response.result.value_confirmation.confirmed_value,
              response.result.value_confirmation.created_at
            );
            this.meterData.value_detections[0].value_confirmation =
              newValueConfirmation;
          } else {
            this.meterData.value_detections[0].value_confirmation.confirmed_value =
              response.result.value_confirmation.confirmed_value;
            this.meterData.value_detections[0].value_confirmation.created_at =
              response.result.value_confirmation.created_at;
          }
          // レポート画面からの呼び出しの時だけ「検針値を変更しました」ダイアログを表示
          if (this.usingPlace === "report") {
            this.messageInfo = new MessageInfo(
              "検針値を変更しました。",
              "report"
            );
            const dialogRef = this.dialog.open(MessageDialogComponent, {
              width: "400px",
              data: this.messageInfo,
            });
            // message-dialogが閉じられたのを検知してクローズ通知を飛ばす
            dialogRef.afterClosed().subscribe((result) => {
              if (result === "close") {
                this.dialogClose.emit();
              }
            });
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert("検針値の確定に失敗しました。");
          }
        }
      );
  }

  doPostActionNoImage(
    detectedVal: string,
    decimal: number,
    valueDetections: ValueDetection[]
  ) {
    let params = new HttpParams();

    let confirmedVal = this.confirmedValueFormat(detectedVal, decimal);

    params = params.set("meter_id", this.meterData.id);
    params = params.set("detected_value", detectedVal);
    params = params.set("confirmed_value", confirmedVal);
    params = params.set("confirmation_channel", "1");
    params = params.set("tenant_id", this.tenantId);

    let pictureURL = "no_image";
    let pictureDate = "no_date";
    if (valueDetections.length > 0) {
      pictureURL = valueDetections[0].picture_url;
      pictureDate = valueDetections[0].picture_date;
    }
    params = params.set("picture_url", pictureURL);
    params = params.set("picture_date", pictureDate);
    this.postValueConfirmationNoImageUrl = `${environment.apiUrl}/value_detections/value_confirmations`;
    this.formControl.setValue({ detected_value: null });

    this.httpClient
      .post(this.postValueConfirmationNoImageUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: PostValueConfirmationWithValueDetectionResponse) => {
          this.keepValue = this.meterData.last_month_value_confirmation;
          this.keepLabel = this.meterData.label;
          this.doGetOneMeterData(this.meterData.id, this.selectedMonth);
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert("検針値の確定に失敗しました。");
          }
        }
      );
  }

  checkLimit(val: string) {
    let formatedNum = "";
    if (val.indexOf(".") > -1) {
      let temp = val.split(".")[1].length;
      if (temp > 2) {
        let ten = Math.pow(10, 3);
        let tmpNum = Math.floor(Number(val) * ten) / ten;

        const fixedNum = tmpNum.toFixed(3);
        const result = parseFloat(fixedNum);

        formatedNum = String(result);
        if (formatedNum !== "NaN") {
          this.formControl.setValue({ detected_value: formatedNum });
        } else {
          this.formControl.setValue({ detected_value: "" });
        }
      }
    }
  }

  doGetOneMeterData(meterId: string, checkedMonth: string) {
    this.getOneMeterDataUrl = `${environment.apiUrl}/meters/${meterId}/tenants/${this.tenantId}/${checkedMonth}/value_detections`;
    this.httpClient
      .get(this.getOneMeterDataUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetInSiteMeterResponse) => {
          this.meterData = response.result.meter;
          this.meterData.last_month_value_confirmation = this.keepValue;
          this.meterData.label = this.keepLabel;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert("メーター情報が取得できませんでした。");
          }
        }
      );
  }

  // debug
  onClickMeterQrcode(
    meter: Meter,
    partName: string,
    tenantName: string,
    label: string
  ) {
    // if (!this.isPartitionTenantReady) {
    //   alert("テナントデータが取得できませんでした。")
    //   return
    // }
    let meterinfo = new MeterInfo();
    meterinfo.id = meter.id;
    meterinfo.name = meter.name;
    meterinfo.label = meter.label; //メーター番号　meter-management-list参照
    meterinfo.partName = partName;
    meterinfo.tenantName = tenantName;

    if (meterinfo.label == undefined || meterinfo.label == null) {
      meterinfo.label = label;
    }

    meterinfo.is_reviewed = meter.is_reviewed;

    const dialogRef = this.dialog.open(MeterQrcodeDialogComponent, {
      width: "800px",
      data: meterinfo,
    });

    dialogRef.afterClosed().subscribe((makeResult) => {
      if (makeResult === "close") {
      }
    });
  }
  // debug
}

// 検針入力値のバリデーション定義
function kenshinValueCheck(c: UntypedFormControl) {
  const REGPATTERN = /^([0-9]+)(\.[0-9]{1,7})?$/;
  if (REGPATTERN.test(c.value)) {
    return null;
  } else {
    return { kenshinValueCheck: { valid: false } };
  }
}
