export const environment = {
  production: true,
  // production: false,
  amplify: {
    Auth: {
      aws_cognito_region: 'ap-northeast-1',
      aws_user_pools_id: 'ap-northeast-1_ychMOZdRY',
      aws_user_pools_web_client_id: '7fjcrqd8appu5ip5ifp35lh3b9',
    }
  },
  apiUrl: 'https://api.rakusugikenshin.com',
  apiKey: 'ixzdsZGbxv3xxRKHjcUIV7NY790NVwrV7GIoosDp',
  apiEnv: 'prd',
};
